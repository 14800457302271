<template>
    <div>
        <el-row>
            <el-col :span="4">
                <el-select v-model="searchObj.type" placeholder="请选择" @change="setList()">
                    <el-option value="0" label="本地">
                    </el-option>
                    <el-option value="1" label="服务器">
                    </el-option>
                </el-select>
            </el-col>
            <el-col :span="10">
                <el-input @change="getList" v-model="searchObj.tag">
                    <template #prepend>tag</template>
                    <template #append>
                        <el-button @click="setList">搜索</el-button>
                    </template>
                </el-input>
            </el-col>
        </el-row>
        <el-table :data="tableData" style="margin-top:20px;" v-loading="tableLoading" border>
            <el-table-column prop="tag" label="tag" :show-overflow-tooltip="true" width="160">
            </el-table-column>
            <el-table-column prop="content" label="content" :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="createTime" label="createTime" width="160">
            </el-table-column>
        </el-table>
        <el-pagination style="margin-top:15px;" @current-change="getList" :current-page.sync="searchObj.currentPage"
            :page-size.sync="searchObj.pageSize" :total="searchObj.total">
        </el-pagination>
    </div>
</template>
  
<script>
export default {
    data() {
        return {
            searchObj: { tag: "", type: "1", currentPage: 1, total: 0 },
            tableData: [],
            tableLoading: false,
        };
    },
    methods: {
        getList() {
            let that = this;
            that.tableLoading = true;
            that.axios.post("Log_WebApi/GetList", that.searchObj).then((response) => {
                that.tableLoading = false;
                that.tableData = response.data.data.data;
                that.searchObj.total = response.data.data.total;
            });
        },
        setList() {
            this.searchObj.currentPage = 1;
            this.getList();
        },


    },

    mounted() {
        this.getList();
    },
};
</script>
<style></style>